@use "../abstracts" as *;

.section-case {
  width: 86%;
  margin: 4rem auto;

  > .title {
    h1 {
      font-size: 3.5rem;
      font-weight: bold;

      span {
        border-bottom: 0.6rem solid $midnightBlue;
      }
    }
  }

  @include responsive(xsmall) {
    width: 100%;

    > .title {
      text-align: center;

      h1 {
        line-height: 24px;
        font-size: 24px;
      }
      h6 {
        margin-top: 10px;
        line-height: 14px;
        font-size: 14px;
      }
    }
  }

  .case-wrapper {
    .case {
      margin-top: 4rem;
      display: flex;
      justify-content: space-between;

      .box {
        width: 30%;
        border-radius: 1rem;
        position: relative;
        color: $color-white;

        a,
        a:hover,
        a:focus,
        a:visited,
        a:active {
          color: inherit;
          cursor: pointer;
          text-decoration: none;
          outline-style: none;
          border-radius: 1rem;
        }
        a {
          width: 100%;
          height: 100%;
          display: block;
          overflow: hidden;

          img {
            transition: all 0.3s;
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 1rem;
          }

          .description {
            z-index: 1;
            position: absolute;
            bottom: 4rem;
            left: 0;
            padding: 0 4rem;
            transition: all 0.5s;

            h3 {
              font-size: 2.2rem;
              margin-bottom: 1rem;

              @include responsive(xsmall) {
                font-size: 18px;
              }
            }

            p {
              font-size: 14px;
            }
          }

          .mask {
            //   z-index: 2;
            position: absolute;
            //   visibility: visible;
            height: 50%;
            width: 100%;
            content: "";
            background: linear-gradient(
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.3) 70%
            );
            top: auto;
            bottom: 0;
            border-radius: 0 0 10px 10px;
            transition: all 0.5s;
          }
        }

        &:hover img {
          // border-radius: 1rem;
          transform: scale(1.05);
        }

        &:hover .description {
          bottom: 6rem;
        }
      }
    }

    @include responsive(xsmall) {
      transition: 0.1s;
      transition-property: transform;
      overflow: hidden;
      .case {
        transition-property: transform;
        transition: 0.4s;
        .box {
          width: 90%;
          margin: 0 5%;
          flex-shrink: 0;
        }
      }
    }
  }

  .swiper-pagination-bullets {
    display: none;

    @include responsive(xsmall) {
      height: 20px;
      display: block;
      // border: 1px solid;
      // margin-top: 15px;
      padding-top: 15px;
      text-align: center;
      transition: 0.3s opacity;
      position: relative;

      .swiper-pagination-bullet {
        cursor: pointer;

        &:not(:last-of-type) {
          margin-right: 20px;
        }

        width: 4px;
        height: 4px;
        display: inline-block;
        border-radius: 100%;
        background: $color-black;
        opacity: 0.2;
        vertical-align: middle;

        &-active {
          background-color: #c7000b;
          opacity: 1;
          position: relative;

          &::before {
            // display: table;
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 14px;
            margin-left: -11px;
            margin-top: -8px;
            border-radius: 7px;
            border: 1px solid #c7000b;
          }
        }
      }
    }
  }
}
