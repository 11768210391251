@use "../abstracts" as *;

.btn-text {
  position: relative;
  //   left: 50%;
  margin: 2rem auto;
  //   padding-top: 0.1rem;
  //   color: $color-white;

  &:link,
  &:visited {
    font-size: $default-font-size;
    color: inherit;
    display: inline-block;
    text-decoration: none;
    border-bottom: 0.3rem solid $midnightBlue;
    padding: 0.3rem;
    transition: all 0.2s;
  }

  &:hover {
    background-color: $midnightBlue;
    color: $color-white !important;
    box-shadow: 0 1rem 2rem rgba($color-black, 0.15);
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: 0 0.5rem 1rem rgba($color-black, 0.15);
    transform: translateY(0);
  }
}
