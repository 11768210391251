@use "../abstracts" as *;

.sidebar {
  z-index: 1;
  position: fixed;
  right: 0;
  bottom: 10rem;
  top: 50%;
  // transform: translateY(-50%);

  font-size: 1.4rem;
  color: $color-white;

  @include responsive(xsmall) {
    bottom: 0;
    right: auto;
    top: auto;

    width: 100%;
  }

  ul {
    // margin: 1rem;
    list-style: none;
    // font-size: 1.4rem;

    .mobile-version {
      display: none;
    }

    @include responsive(xsmall) {
      display: flex;
      width: 100%;
      justify-content: center;
      .pc-version {
        display: none;
      }

      .mobile-version {
        display: block;
      }
    }

    li {
      position: relative;
      padding: 1rem;
      transition: all 0.5s;

      @include responsive(xsmall) {
        // width: 25%;
        width: 33.33%;
        height: auto;
      }
      .phonenumber {
        position: absolute;
        left: 100%;

        top: 0;
        // background-color: $skyBlue;
        background-color: #147acf;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.8rem;
        font-size: 2rem;
        font-weight: bold;
        width: 15.7rem;
        height: 100%;
        border-radius: 0.5rem 0 0 0.5rem;

        transition: all 0.5s;
      }

      .content {
        svg {
          width: 2.5rem;
          height: 50%;

          display: block;
          margin: 0.2rem auto;
        }

        @include responsive(xsmall) {
          font-size: 14px;
          text-align: center;
          // padding: 2rem 0;
          padding-bottom: 1rem;

          svg {
            width: 20px;
            padding: 1rem 0;
          }
        }
      }

      a,
      a:active {
        color: inherit;
        text-decoration: none;
        // background-color: $skyBlue;
      }

      // color: white;
      &:nth-of-type(1) {
        border-radius: 0.5rem 0 0 0;
      }
      &:last-of-type {
        border-radius: 0 0 0 0.5rem;
      }
      background-color: $midnightBlue;
      margin-bottom: 0.1rem;

      &:hover {
        // background-color: $skyBlue;
        background-color: #147acf;
        // color: $midnightBlue;
      }

      &:hover .phonenumber {
        left: -16rem;
      }

      &:hover:nth-of-type(1) {
        border-radius: 0 0 0 0;
      }
    }
  }
}
