@use "../abstracts" as *;

.topbar {
  background-color: LightGrey;
  font-size: 1.2rem;
  color: $darkGrey;

  padding: 1rem 0;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 86%;
    // height: 3rem;
    margin: 0 auto;

    svg {
      width: 1.2rem;
      margin-right: 0.8rem;
    }
  }

  // @include responsive(large) {
  //   // width < 1200?
  //   font-size: 56.25%; //1 rem = 9px, 9/16 = 56.25%
  // }
  // @include responsive(medium) {
  //   // width < 992?
  //   font-size: 50%; //1 rem = 8px, 8/16 = 50%
  // }
  // @include responsive(small) {
  //   // width < 768?
  //   font-size: 43.75%; //1 rem = 7px, 7/16 = 43.75%
  // }
  @include responsive(xsmall) {
    // width < 576?

    font-size: 2rem;
    .content {
      display: block;
      text-align: center;
      width: 100%;

      svg {
        width: 2rem;
      }
    }
    &__contact {
      margin-top: 1rem;
    }
  }
}
