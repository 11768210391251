@use "breakpoints" as *;
@mixin position($position, $top, $left, $bottom, $right, $width, $height) {
  position: $position;
  top: $top;
  left: $left;
  bottom: $bottom;
  right: $right;
  width: $width;
  height: $height;
}
@mixin align($flex, $justify-content, $align-items, $height) {
  display: $flex;
  align-items: $align-items;
  justify-content: $justify-content;
  height: $height;
}
@mixin button(
  $color1,
  $color2,
  $textColor,
  $height,
  $fontSize,
  $bdr,
  $paddingVertical,
  $paddingHorizontal
) {
  background: linear-gradient(to right, $color1, $color2);
  color: $textColor;
  text-decoration: none;
  height: $height;
  line-height: $height;
  display: inline-block;
  font-size: $fontSize;
  font-weight: 400;
  border-radius: $bdr;
  padding: $paddingVertical $paddingHorizontal;
  border: none;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
}
@mixin animation($name, $duration, $timeFunction, $translate1, $translate2) {
  animation: $name $duration $timeFunction;
  @keyframes #{$name} {
    0% {
      opacity: 0;
      transform: translateY($translate1);
    }
    100% {
      opacity: 1;
      transform: translateY($translate2);
    }
  }
}
@mixin toggleSpans($bg, $width, $height, $mt) {
  display: block;
  background: $bg;
  width: $width;
  height: $height;
  margin-top: $mt;
  transition: all 0.3s linear;
}
@mixin status($bg, $color) {
  padding: 0.6rem 1.3rem;
  background: $bg;
  color: $color;
  border-radius: 0.6rem;
  font-size: 1.3rem;
  text-transform: capitalize;
}
@mixin control($height, $paddingy, $paddingx, $color) {
  display: block;
  width: 100%;
  border-radius: 0.4rem;
  outline: none;
  font-size: 1.6rem;
  border: 1px solid $color;
  height: $height;
  padding: $paddingy $paddingx;
}
@mixin responsive($breakpoint) {
  @if $breakpoint == large {
    @media only screen and (max-width: $lg) {
      @content;
    }
  }
  @if $breakpoint == medium {
    @media only screen and (max-width: $md) {
      @content;
    }
  }
  @if $breakpoint == small {
    @media only screen and (max-width: $sm) {
      @content;
    }
  }
  @if $breakpoint == xsmall {
    @media only screen and (max-width: $xsm) {
      @content;
    }
  }
}

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}
