@use "../abstracts" as *;

.cert-query {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  background-color: lightgray;
  //   border: 1px solid;

  .input-field {
    margin-bottom: 2rem;
    width: 100%;
    text-align: center;

    input {
      width: 80%;
      font-size: 1.5rem;
      padding: 1.5rem 2rem;
      border-radius: 0.5rem;
      border: none;
      //   background-color: var(--color-grey-light-2);
      //   transition: all 0.3s;
    }
  }

  .button {
    padding: 0.5rem 1rem;
    margin-bottom: 2rem;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;

    font-size: 1.6rem;

    border-radius: 0.2rem;
    padding: 1rem 2rem;
  }

  .result {
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    width: 300px;
  }
}
