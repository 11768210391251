@use "../abstracts" as *;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  // 10 / 16 * 100 = 62.5%
  font-size: 62.5%;

  @include responsive(large) {
    // width < 1200?
    font-size: 56.25%; //1 rem = 9px, 9/16 = 56.25%
  }
  @include responsive(medium) {
    // width < 992?
    font-size: 50%; //1 rem = 8px, 8/16 = 50%
  }
  @include responsive(small) {
    // width < 768?
    font-size: 43.75%; //1 rem = 7px, 7/16 = 43.75%
  }
  @include responsive(xsmall) {
    // width < 576?
    font-size: 37.5%; //1 rem = 6px, 6/16 = 37.5%
  }
}

body {
  // background-color: lightcyan;
  // font-family: "Lato", sans-serif;
}
