* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}
@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media only screen and (max-width: 62em) {
  html {
    font-size: 50%;
  }
}
@media only screen and (max-width: 48em) {
  html {
    font-size: 43.75%;
  }
}
@media only screen and (max-width: 36em) {
  html {
    font-size: 37.5%;
  }
}

.container {
  width: 86%;
  margin: 10rem auto;
  display: flex;
}
.container .left {
  width: 20%;
  background-color: #004a89;
}
.container .left ul {
  list-style: none;
  color: #fff;
  text-align: center;
  background-color: #004a89;
}
.container .left ul li {
  padding: 1rem;
  font-size: 1.6rem;
  transition: all 0.3s;
  border-bottom: 1px solid #147acf;
}
.container .left ul li:hover {
  background-color: #147acf;
  cursor: pointer;
}
.container .left a,
.container .left a:active {
  text-decoration: none;
}
.container .right {
  width: 80%;
}
@media only screen and (max-width: 36em) {
  .container {
    width: 100%;
    margin: 0;
    flex-direction: column;
  }
  .container .left {
    width: 100%;
  }
  .container .left ul {
    display: flex;
    flex-wrap: wrap;
  }
  .container .left ul li {
    font-size: 14px;
  }
  .container .left ul li a {
    padding: 1rem;
  }
  .container .right {
    width: 100%;
  }
  .container h1 {
    font-size: 16px;
  }
  .container p {
    font-size: 14px;
  }
  .container li {
    font-size: 14px;
  }
}

.pr {
  position: relative;
}

.group {
  margin-bottom: 1.3rem;
}
.group span {
  font-size: 1.5rem;
  display: inline-block;
  color: #1c1d1f;
  cursor: pointer;
}
.group__control {
  display: block;
  width: 100%;
  border-radius: 0.4rem;
  outline: none;
  font-size: 1.6rem;
  border: 1px solid #e2e4eb;
  height: 5rem;
  padding: 0 1.5rem;
}
.group__textarea {
  display: block;
  width: 100%;
  border-radius: 0.4rem;
  outline: none;
  font-size: 1.6rem;
  border: 1px solid #e2e4eb;
  height: 10rem;
  padding: 1.5rem 1.5rem;
}
.group__textarea::placeholder {
  font-family: sans-serif;
  font-size: 1.5rem;
}
@media only screen and (max-width: 36em) {
  .group__textarea::placeholder {
    font-size: 1.3rem;
  }
}

::placeholder {
  color: #1c1d1f;
  font-size: 1.5rem;
}
@media only screen and (max-width: 36em) {
  ::placeholder {
    font-size: 1.3rem;
  }
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.y-center {
  align-items: center;
}

.p-15 {
  padding: 1.5rem;
}

.mr-minus-15 {
  margin-right: -1.5rem;
}

.ml-minus-15 {
  margin-left: -1.5rem;
}

.mb-55 {
  margin-bottom: 5.5rem !important;
}

.animation {
  transform: translateY(-10rem) scale(0);
  opacity: 0;
  transition: all 0.8s ease-out;
}

.animate {
  transform: translateY(0) scale(1);
  opacity: 1;
}

.headingAnimation {
  transform: translateY(-10rem) scale(0) rotate(-2deg);
  opacity: 0;
  transition: all 0.8s ease-out;
}

.headingAnimate {
  transform: translateY(0) scale(1) rotate(-2deg);
  opacity: 1;
}

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, #5bc0de, #0a1a2e);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.2rem;
  transition: all 0.2s;
}
.heading-secondary:hover {
  transform: skewY(2deg) skewX(15deg) scale(1.1);
  text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2);
}

a,
a:active {
  color: inherit;
  text-decoration: none;
}

.header {
  width: 100%;
  position: relative;
}
.header__img {
  width: 100%;
  position: relative;
  padding-top: 20%;
  overflow: hidden;
}
.header__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.header h1 {
  font-size: 5rem;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.btn-text {
  position: relative;
  margin: 2rem auto;
}
.btn-text:link, .btn-text:visited {
  font-size: 1.6rem;
  color: inherit;
  display: inline-block;
  text-decoration: none;
  border-bottom: 0.3rem solid #004a89;
  padding: 0.3rem;
  transition: all 0.2s;
}
.btn-text:hover {
  background-color: #004a89;
  color: #fff !important;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}
.btn-text:active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transform: translateY(0);
}

.card {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 52rem;
}
.card__side {
  height: 52rem;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
}
.card__side--front {
  background-color: #fff;
}
.card__side--back {
  transform: rotateY(180deg);
}
.card__side--back-1 {
  background-image: linear-gradient(to right bottom, #ffb900, #ff7730);
}
.card__side--back-2 {
  background-image: linear-gradient(to right bottom, #7ed56f, #28b485);
}
.card__side--back-3 {
  background-image: linear-gradient(to right bottom, #2998ff, #5643fa);
}
.card:hover .card__side--front {
  color: red;
  transform: rotateY(-180deg);
}
.card:hover .card__side--back {
  transform: rotateY(0);
}
.card__picture {
  background-size: cover;
  height: 23rem;
  background-blend-mode: screen;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.card__picture--1 {
  background-image: linear-gradient(to right bottom, #ffb900, #ff7730), url(../../public/assets/images/3.jpg);
}
.card__picture--2 {
  background-image: linear-gradient(to right bottom, #7ed56f, #28b485), url(../../public/assets/images/3.jpg);
}
.card__picture--3 {
  background-image: linear-gradient(to right bottom, #2998ff, #5643fa), url(../../public/assets/images/3.jpg);
}
.card__heading {
  font-size: 2.8rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: right;
  color: #fff;
  position: absolute;
  top: 12rem;
  right: 2rem;
  width: 75%;
}
.card__heading-span {
  padding: 1rem 1.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.card__heading-span--1 {
  background-image: linear-gradient(to right bottom, rgba(255, 185, 0, 0.85), rgba(255, 119, 48, 0.85));
}
.card__heading-span--2 {
  background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.85), rgba(40, 180, 133, 0.85));
}
.card__heading-span--3 {
  background-image: linear-gradient(to right bottom, rgba(41, 152, 255, 0.85), rgba(86, 67, 250, 0.85));
}
.card__details {
  padding: 3rem;
}
.card__details ul {
  list-style: none;
  width: 80%;
  margin: 0 auto;
}
.card__details ul li {
  text-align: center;
  font-size: 1.5rem;
  padding: 1rem;
}
.card__details ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.card__cta {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
}
.card__price-box {
  text-align: center;
  color: #fff;
  margin-bottom: 8rem;
}
.card__price-only {
  font-size: 1.4rem;
  text-transform: uppercase;
}
.card__price-value {
  font-size: 6rem;
  font-weight: 100;
}
@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  .card {
    height: auto;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  }
  .card__side {
    height: auto;
    position: relative;
    box-shadow: none;
  }
  .card__side--back {
    transform: rotateY(0);
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
  }
  .card:hover .card__side--front {
    transform: rotateY(0);
  }
  .card__details {
    padding: 1rem 3rem;
  }
  .card__cta {
    position: relative;
    top: 0%;
    left: 0;
    transform: translate(0);
    width: 100%;
    padding: 7rem 4rem 4rem 4rem;
  }
  .card__price-box {
    margin-bottom: 3rem;
  }
  .card__price-value {
    font-size: 4rem;
  }
}

.row {
  margin: 0 auto;
}
.row:not(:last-child) {
  margin-bottom: 8rem;
}
.row [class^=col-] {
  float: left;
}
.row [class^=col-]:not(:last-child) {
  margin-right: 3rem;
}
.row .col-1-of-2 {
  width: calc((100% - 3rem) / 2);
}
.row .col-1-of-3 {
  width: calc((100% - 2 * 3rem) / 3);
}
.row .col-2-of-3 {
  width: calc(2 * (100% - 2 * 3rem) / 3 + 3rem);
}
.row .col-1-of-4 {
  width: calc((100% - 3 * 3rem) / 4);
}
.row .col-2-of-4 {
  width: calc(2 * (100% - 3 * 3rem) / 4 + 3rem);
}
.row .col-3-of-4 {
  width: calc(3 * (100% - 3 * 3rem) / 4 + 2 * 3rem);
}

.topbar {
  background-color: LightGrey;
  font-size: 1.2rem;
  color: #212121;
  padding: 1rem 0;
}
.topbar .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 86%;
  margin: 0 auto;
}
.topbar .content svg {
  width: 1.2rem;
  margin-right: 0.8rem;
}
@media only screen and (max-width: 36em) {
  .topbar {
    font-size: 2rem;
  }
  .topbar .content {
    display: block;
    text-align: center;
    width: 100%;
  }
  .topbar .content svg {
    width: 2rem;
  }
  .topbar__contact {
    margin-top: 1rem;
  }
}

.sidebar {
  z-index: 1;
  position: fixed;
  right: 0;
  bottom: 10rem;
  top: 50%;
  font-size: 1.4rem;
  color: #fff;
}
@media only screen and (max-width: 36em) {
  .sidebar {
    bottom: 0;
    right: auto;
    top: auto;
    width: 100%;
  }
}
.sidebar ul {
  list-style: none;
}
.sidebar ul .mobile-version {
  display: none;
}
@media only screen and (max-width: 36em) {
  .sidebar ul {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .sidebar ul .pc-version {
    display: none;
  }
  .sidebar ul .mobile-version {
    display: block;
  }
}
.sidebar ul li {
  position: relative;
  padding: 1rem;
  transition: all 0.5s;
  background-color: #004a89;
  margin-bottom: 0.1rem;
}
@media only screen and (max-width: 36em) {
  .sidebar ul li {
    width: 33.33%;
    height: auto;
  }
}
.sidebar ul li .phonenumber {
  position: absolute;
  left: 100%;
  top: 0;
  background-color: #147acf;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.8rem;
  font-size: 2rem;
  font-weight: bold;
  width: 15.7rem;
  height: 100%;
  border-radius: 0.5rem 0 0 0.5rem;
  transition: all 0.5s;
}
.sidebar ul li .content svg {
  width: 2.5rem;
  height: 50%;
  display: block;
  margin: 0.2rem auto;
}
@media only screen and (max-width: 36em) {
  .sidebar ul li .content {
    font-size: 14px;
    text-align: center;
    padding-bottom: 1rem;
  }
  .sidebar ul li .content svg {
    width: 20px;
    padding: 1rem 0;
  }
}
.sidebar ul li a,
.sidebar ul li a:active {
  color: inherit;
  text-decoration: none;
}
.sidebar ul li:nth-of-type(1) {
  border-radius: 0.5rem 0 0 0;
}
.sidebar ul li:last-of-type {
  border-radius: 0 0 0 0.5rem;
}
.sidebar ul li:hover {
  background-color: #147acf;
}
.sidebar ul li:hover .phonenumber {
  left: -16rem;
}
.sidebar ul li:hover:nth-of-type(1) {
  border-radius: 0 0 0 0;
}

.navbar {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 0 3%;
  color: #fff;
  background: rgba(9, 30, 55, 0.8);
  transition: 0.1s;
}
@media only screen and (max-width: 36em) {
  .navbar {
    display: block;
    padding: 0;
  }
}
.navbar .navigation {
  width: 70%;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.navbar .navigation .logo {
  font-size: 5rem;
  font-weight: bold;
}
.navbar .navigation__checkbox, .navbar .navigation__button {
  display: none;
}
.navbar .navigation nav {
  margin-right: 3rem;
  width: 80%;
  font-size: 1.6rem;
}
.navbar .navigation nav > ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
}
.navbar .navigation nav > ul > li {
  display: flex;
  text-align: center;
  position: relative;
  width: 100%;
}
.navbar .navigation nav > ul > li:hover > a {
  color: #fff;
  background-color: rgba(65, 65, 65, 0.85);
}
.navbar .navigation nav > ul > li > a.active,
.navbar .navigation nav > ul > li a:focus {
  color: #fff;
  background-color: rgba(65, 65, 65, 0.85);
}
.navbar .navigation nav > ul > li span {
  display: none;
}
.navbar .navigation nav > ul > li > a {
  width: 100%;
  color: #d9d9d9;
  padding: 3rem 0;
  text-align: center;
  transition: all 0.3s;
}
.navbar .navigation nav > ul > li > ul {
  top: 100%;
  left: 0;
  width: 100%;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  list-style-type: none;
  transition: all 0.5s ease-in;
}
.navbar .navigation nav > ul > li > ul.open {
  position: 100%;
  opacity: 1;
  visibility: visible;
}
.navbar .navigation nav > ul > li > ul li {
  text-align: center;
  font-size: 1.4rem;
  padding: 1rem;
  position: relative;
  background: rgba(65, 65, 65, 0.85);
}
.navbar .navigation nav > ul > li > ul li:not(:last-child) {
  margin-bottom: 1px;
}
.navbar .navigation nav > ul > li > ul li a {
  color: #bcbcbc;
  transition: all 0.5s;
}
.navbar .navigation nav > ul > li > ul li:hover > a {
  color: #fff;
}
@media only screen and (max-width: 36em) {
  .navbar .navigation {
    display: block;
    width: 100%;
  }
  .navbar .navigation .logo {
    font-size: 36px;
    padding: 3px 3%;
    height: 60px;
    line-height: 36px;
    padding-top: 12px;
    vertical-align: middle;
    display: inline-block;
  }
  .navbar .navigation__button {
    display: inline-block;
    position: absolute;
    background-color: #fff;
    height: 42px;
    width: 42px;
    top: 9px;
    right: 3%;
    border-radius: 50%;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;
  }
  .navbar .navigation__icon {
    position: relative;
    margin-top: 21px;
  }
  .navbar .navigation__icon, .navbar .navigation__icon::before, .navbar .navigation__icon::after {
    width: 18px;
    height: 2px;
    background-color: #333;
    display: inline-block;
  }
  .navbar .navigation__icon::before, .navbar .navigation__icon::after {
    content: "";
    position: absolute;
    left: 0;
    transition: all 0.2s;
  }
  .navbar .navigation__icon::before {
    top: -4.8px;
  }
  .navbar .navigation__icon::after {
    top: 4.8px;
  }
  .navbar .navigation .navigation__button:hover .navigation__icon::before {
    top: -6px;
  }
  .navbar .navigation .navigation__button:hover .navigation__icon::after {
    top: 6px;
  }
  .navbar .navigation .navigation__checkbox:checked + .navigation__button .navigation__icon {
    background-color: transparent;
  }
  .navbar .navigation .navigation__checkbox:checked + .navigation__button .navigation__icon::before {
    top: 0;
    transform: rotate(135deg);
  }
  .navbar .navigation .navigation__checkbox:checked + .navigation__button .navigation__icon::after {
    top: 0;
    transform: rotate(-135deg);
  }
  .navbar .navigation nav {
    display: none;
    width: 100%;
  }
  .navbar .navigation nav > ul {
    display: block;
  }
  .navbar .navigation nav > ul > li {
    display: block;
    width: auto;
    border-top: 1px solid #ddd;
    text-align: left;
    position: relative;
  }
  .navbar .navigation nav > ul > li:hover > a {
    color: #fff;
    background-color: transparent;
  }
  .navbar .navigation nav > ul > li > a {
    width: auto;
    padding: 12px;
    font-size: 12px;
    display: inline-block;
    padding-left: 5%;
    font-weight: 700;
  }
  .navbar .navigation nav > ul > li > span {
    position: absolute;
    top: 6px;
    right: 7.5%;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
  }
  .navbar .navigation nav > ul > li > ul {
    position: relative;
    width: auto;
    display: none;
  }
  .navbar .navigation nav > ul > li > ul.open {
    bottom: auto;
    top: 100%;
    display: block;
  }
  .navbar .navigation nav > ul > li > ul > li {
    display: block;
    width: auto;
    border-top: 1px solid #ddd;
    text-align: left;
    position: relative;
    padding: 0 10%;
    background-color: transparent;
  }
  .navbar .navigation nav > ul > li > ul > li > a {
    width: auto;
    padding: 12px;
    font-size: 12px;
    display: inline-block;
    font-weight: 400;
  }
  .navbar .navigation .navigation__checkbox:checked ~ nav {
    display: block;
  }
}
.navbar .lls {
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 30%;
}
@media only screen and (max-width: 36em) {
  .navbar .lls {
    display: none;
    width: 100%;
  }
}
.navbar .lls a::before {
  width: 1.3rem;
  height: 1.6rem;
  background-image: url(../../public/assets/images/icon-member.svg);
  content: "";
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: -0.5rem 1rem 0 0;
}
.navbar .lls a:hover {
  color: #2095f7;
}
.navbar .lls a {
  color: inherit;
  opacity: 0.8;
  padding: 0.2rem 0;
  font-size: 1.4rem;
  transition: color 0.3s;
}
.navbar .lls .lang {
  padding-left: 3rem;
}
.navbar .lls .lang span::before,
.navbar .lls .lang span::after {
  width: 1.7rem;
  height: 1.7rem;
  content: "";
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.navbar .lls .lang span::before {
  background-image: url(../../public/assets/images/icon-lang.svg);
  margin: -0.5rem 1rem 0 0;
}
.navbar .lls .lang span::after {
  background-image: url(../../public/assets/images/icon-downarrow.png);
  margin: -0.5rem 0 0 1rem;
}
.navbar .lls .lang span:hover {
  cursor: pointer;
  color: #2095f7;
}
.navbar .lls .lang span {
  font-size: 1.4rem;
  opacity: 0.8;
  transition: color 0.3s;
}
.navbar .lls .lang ul {
  display: none;
}
.navbar .lls .search {
  margin-left: 3rem;
}
.navbar .lls .search button::after {
  width: 1.7rem;
  height: 1.7rem;
  background-image: url(../../public/assets/images/icon-search.svg);
  content: "";
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: -5px 0 0 10px;
}
.navbar .lls .search button {
  border: 0.2rem solid #004a89;
  background: #004a89;
  color: white;
  font-size: 1.4rem;
  padding: 10px 25px;
}
.navbar .lls .search button:hover {
  background: #147acf;
  border-color: #147acf;
  cursor: pointer;
}
.navbar .lls .search .form-wrap {
  display: none;
}

.banner {
  position: relative;
  background: #000;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
}
.banner ul {
  list-style: none;
  position: relative;
  overflow: hidden;
  width: 4200px;
  transition: left 1s;
}
.banner ul li {
  width: 1400px;
  float: left;
  height: 65vh;
  position: relative;
}
.banner ul li:hover {
  cursor: pointer;
}
.banner ul li .text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
  color: #fff;
}
.banner ul li .text h1 {
  font-size: 6rem;
  font-weight: 600;
  text-shadow: 1px 1px 1px #000;
  transform: perspective(50rem) rotateX(10deg);
}
.banner ul li .text p {
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 1px 1px 1px #000;
}
.banner ul li:nth-of-type(1) {
  background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(../../public/assets/images/banner-sailing-tallship-gm176827711-1331024.jpg);
  background-size: cover;
}
.banner ul li:nth-of-type(1) p {
  font-size: 3rem;
  font-weight: bold;
}
.banner ul li:nth-last-of-type(2) {
  background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url(../../public/assets/images/banner-various-field-industry-concept-sustainable-society.jpg);
  background-size: cover;
}
.banner ul li:nth-last-of-type(2) p {
  font-weight: bold;
  width: 50rem;
}
.banner ul li:nth-of-type(3) {
  background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(../../public/assets/images/banner-businessmen-making-handshake-with-partner-greeting-dealing-merger-and-acquisition.jpg);
  background-size: cover;
}
.banner ul li:nth-of-type(3) p {
  font-size: 2rem;
  font-weight: bold;
  width: 50rem;
}
.banner .dots {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 3rem;
  width: 100%;
}
.banner .dots .dot {
  width: 2rem;
  height: 2rem;
  margin: 0 2rem;
  border-radius: 50%;
  border: 2px solid white;
  background-color: transparent;
  cursor: pointer;
}
.banner .dots .dot.active {
  background-color: white;
}

.section-svc {
  margin: 5rem auto;
  padding: 10rem 0;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #55c57a), url(../../public/assets/images/SVC-ISO-background.jpeg);
  background-size: cover;
  background-position: center;
}
@media only screen and (max-width: 36em) {
  .section-svc {
    margin: 0 auto;
    padding: 5rem 0;
    background-image: linear-gradient(to bottom, white, rgba(85, 197, 122, 0.5)), url(../../public/assets/images/SVC-ISO-background.jpeg);
  }
}
.section-svc .title {
  position: relative;
  right: 7%;
  bottom: 10rem;
  padding-left: 14%;
  background-color: white;
}
.section-svc .title h1 {
  font-size: 3.5rem;
}
.section-svc .title h3 {
  color: #004a89;
  font-size: 1.6rem;
}
@media only screen and (max-width: 36em) {
  .section-svc .title {
    right: auto;
    bottom: auto;
    text-align: center;
    padding-left: 0;
    background-color: transparent;
  }
  .section-svc .title h1 {
    font-size: 24px;
  }
  .section-svc .title h3 {
    font-size: 14px;
  }
}
.section-svc p {
  font-size: 2rem;
  color: #fff;
  margin-top: 2.1rem;
  width: 50%;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem 5rem;
}
@media only screen and (max-width: 36em) {
  .section-svc p {
    width: 100%;
    text-align: center;
    background: none;
    color: #666666;
    font-size: 14px;
  }
}
.section-svc .services {
  width: 86%;
  margin: 0 auto;
  position: relative;
  top: 15rem;
  color: #fff;
  font-size: 2rem;
}
@media only screen and (max-width: 36em) {
  .section-svc .services {
    top: auto;
    margin-top: 5rem;
    font-size: 16px;
  }
}
.section-svc .services .svc {
  width: 33.3333333333%;
  display: inline-block;
  overflow: hidden;
  position: relative;
}
@media only screen and (max-width: 36em) {
  .section-svc .services .svc {
    display: block;
    width: 100%;
    box-shadow: 0px 0px 10px 0px #000;
  }
  .section-svc .services .svc:not(:last-of-type) {
    margin-bottom: 2rem;
  }
}
.section-svc .services .svc img {
  width: 100%;
  vertical-align: top;
  transition: all 3s;
}
.section-svc .services .svc img:hover {
  transform: scale(1.1);
}
.section-svc .services .svc__info {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30%;
  color: inherit;
}
.section-svc .services .svc__info .title-en {
  position: absolute;
  top: 1rem;
  left: 1rem;
}
.section-svc .services .svc__info .title-cn {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.section-industry {
  width: 86%;
  margin: 20rem auto 10rem;
  position: relative;
  overflow: hidden;
  height: 60rem;
  color: #fff;
}
@media only screen and (max-width: 36em) {
  .section-industry {
    width: 100%;
    height: auto;
    margin: 0;
  }
}
.section-industry .left {
  width: 30%;
  padding: 10% 5% 0 5%;
  background-image: url(../../public/assets/images/OK.jpg);
  background-size: cover;
  transition: background-image 2s ease-in-out;
}
.section-industry .left h1 {
  font-size: 3.5rem;
}
.section-industry .left h3 {
  font-size: 1.6rem;
}
.section-industry .left p {
  font-size: 1.6rem;
  margin-top: 2.1rem;
  line-height: 2.9rem;
}
@media only screen and (max-width: 36em) {
  .section-industry .left h1 {
    font-size: 24px;
    line-height: 36px;
  }
  .section-industry .left h3,
  .section-industry .left p {
    font-size: 14px;
    line-height: 21px;
  }
  .section-industry .left p {
    margin-top: 10px;
  }
}
.section-industry .right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 70%;
}
@media only screen and (max-width: 36em) {
  .section-industry .right {
    display: block;
    text-align: center;
  }
}
.section-industry .right ul {
  border: 1px solid;
  padding-left: 40px;
}
@media only screen and (max-width: 36em) {
  .section-industry .right ul {
    border: none;
  }
}
.section-industry .right .box {
  height: 40%;
  overflow: hidden;
  color: #fff;
  position: relative;
  transition: all 1s ease;
  background-size: 100% 100%;
  background-position: center;
  margin-left: 1rem;
}
@media only screen and (max-width: 36em) {
  .section-industry .right .box {
    padding: 6px;
    text-align: left;
    height: 23%;
    box-shadow: 0px 0px 10px 0px #000;
  }
  .section-industry .right .box:not(:last-of-type) {
    margin-bottom: 12px;
  }
}
.section-industry .right .box a,
.section-industry .right .box a:focus,
.section-industry .right .box a:active {
  color: inherit;
  text-decoration: none;
}
.section-industry .right .box .info-cell {
  opacity: 0;
  transition: all 1s ease;
  background: rgba(0, 0, 0, 0.6);
  margin: 2rem auto;
}
@media only screen and (max-width: 36em) {
  .section-industry .right .box .info-cell {
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
    overflow: hidden;
    height: 70%;
    font-size: 12px;
    line-height: 18px;
  }
}
.section-industry .right .box .info-cell .title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 36em) {
  .section-industry .right .box .info-cell .title {
    display: none;
  }
}
.section-industry .right .box .title-cell {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5rem;
  padding: 0 2rem;
  font-size: 1.8rem;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.5);
  line-height: 5rem;
  transition: all 1s ease;
}
@media only screen and (max-width: 36em) {
  .section-industry .right .box .title-cell {
    font-size: 14px;
  }
}
.section-industry .right .box:hover {
  background-size: 110% 110%;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.5);
}
.section-industry .right .box:hover .title-cell {
  opacity: 0;
}
.section-industry .right .box:hover .info-cell {
  opacity: 1;
}
.section-industry .right > div:nth-of-type(1) {
  width: 35%;
  background-image: url(../../public/assets/images/Industry-data-science-big-technology-scientist-computing.jpg);
}
.section-industry .right > div:nth-of-type(2) {
  width: 60%;
  background-image: url(../../public/assets/images/Industry-future-building-construction-engineering-project-concept.jpg);
}
.section-industry .right > div:nth-of-type(3) {
  width: 60%;
  background-image: url(../../public/assets/images/Industry-balanced-diet-healthy-food-v1.jpg);
}
.section-industry .right > div:nth-of-type(4) {
  width: 35%;
  background-image: url(../../public/assets/images/Industry-designer-sketching-drawing-design-development-product-plan-draft-chair-armchair.jpg);
}
@media only screen and (max-width: 36em) {
  .section-industry .right > div {
    width: 100% !important;
  }
}
.section-industry .right > a {
  color: #004a89;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.section-case {
  width: 86%;
  margin: 4rem auto;
}
.section-case > .title h1 {
  font-size: 3.5rem;
  font-weight: bold;
}
.section-case > .title h1 span {
  border-bottom: 0.6rem solid #004a89;
}
@media only screen and (max-width: 36em) {
  .section-case {
    width: 100%;
  }
  .section-case > .title {
    text-align: center;
  }
  .section-case > .title h1 {
    line-height: 24px;
    font-size: 24px;
  }
  .section-case > .title h6 {
    margin-top: 10px;
    line-height: 14px;
    font-size: 14px;
  }
}
.section-case .case-wrapper .case {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
}
.section-case .case-wrapper .case .box {
  width: 30%;
  border-radius: 1rem;
  position: relative;
  color: #fff;
}
.section-case .case-wrapper .case .box a,
.section-case .case-wrapper .case .box a:hover,
.section-case .case-wrapper .case .box a:focus,
.section-case .case-wrapper .case .box a:visited,
.section-case .case-wrapper .case .box a:active {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  outline-style: none;
  border-radius: 1rem;
}
.section-case .case-wrapper .case .box a {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}
.section-case .case-wrapper .case .box a img {
  transition: all 0.3s;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 1rem;
}
.section-case .case-wrapper .case .box a .description {
  z-index: 1;
  position: absolute;
  bottom: 4rem;
  left: 0;
  padding: 0 4rem;
  transition: all 0.5s;
}
.section-case .case-wrapper .case .box a .description h3 {
  font-size: 2.2rem;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 36em) {
  .section-case .case-wrapper .case .box a .description h3 {
    font-size: 18px;
  }
}
.section-case .case-wrapper .case .box a .description p {
  font-size: 14px;
}
.section-case .case-wrapper .case .box a .mask {
  position: absolute;
  height: 50%;
  width: 100%;
  content: "";
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 70%);
  top: auto;
  bottom: 0;
  border-radius: 0 0 10px 10px;
  transition: all 0.5s;
}
.section-case .case-wrapper .case .box:hover img {
  transform: scale(1.05);
}
.section-case .case-wrapper .case .box:hover .description {
  bottom: 6rem;
}
@media only screen and (max-width: 36em) {
  .section-case .case-wrapper {
    transition: 0.1s;
    transition-property: transform;
    overflow: hidden;
  }
  .section-case .case-wrapper .case {
    transition-property: transform;
    transition: 0.4s;
  }
  .section-case .case-wrapper .case .box {
    width: 90%;
    margin: 0 5%;
    flex-shrink: 0;
  }
}
.section-case .swiper-pagination-bullets {
  display: none;
}
@media only screen and (max-width: 36em) {
  .section-case .swiper-pagination-bullets {
    height: 20px;
    display: block;
    padding-top: 15px;
    text-align: center;
    transition: 0.3s opacity;
    position: relative;
  }
  .section-case .swiper-pagination-bullets .swiper-pagination-bullet {
    cursor: pointer;
    width: 4px;
    height: 4px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    opacity: 0.2;
    vertical-align: middle;
  }
  .section-case .swiper-pagination-bullets .swiper-pagination-bullet:not(:last-of-type) {
    margin-right: 20px;
  }
  .section-case .swiper-pagination-bullets .swiper-pagination-bullet-active {
    background-color: #c7000b;
    opacity: 1;
    position: relative;
  }
  .section-case .swiper-pagination-bullets .swiper-pagination-bullet-active::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 14px;
    margin-left: -11px;
    margin-top: -8px;
    border-radius: 7px;
    border: 1px solid #c7000b;
  }
}

.section-about {
  width: 86%;
  padding: 10rem;
  margin: 10rem auto;
  color: #fff;
  background-image: linear-gradient(to right bottom, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.9)), url(../../public/assets/images/about.jpg);
  background-size: cover;
  display: flex;
  justify-content: space-between;
}
.section-about .sabout,
.section-about .join {
  position: relative;
  padding: 5rem 3rem;
  width: 40%;
  background: rgba(91, 192, 222, 0.5);
  border-radius: 1rem;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  transition: transform 0.3s;
}
.section-about .sabout > *,
.section-about .join > * {
  text-align: center;
}
.section-about .sabout:hover,
.section-about .join:hover {
  cursor: pointer;
  transform: translateY(-1.5rem) scale(1.03);
}
@media only screen and (max-width: 36em) {
  .section-about .sabout,
  .section-about .join {
    padding: 2rem;
    width: 100%;
  }
}
.section-about h1 {
  font-size: 3.5rem;
}
.section-about p {
  font-size: 1.6rem;
}
.section-about a,
.section-about a:active {
  color: inherit;
  text-decoration: none;
}
@media only screen and (max-width: 36em) {
  .section-about {
    width: 100%;
    margin: 0 auto;
    padding: 5rem 3%;
    letter-spacing: normal;
    flex-direction: column;
  }
  .section-about .sabout {
    margin-bottom: 2rem !important;
  }
  .section-about .join {
    background: rgba(51, 51, 51, 0.77) !important;
  }
  .section-about h1 {
    font-size: 24px;
    line-height: 36px;
  }
  .section-about h3,
  .section-about p {
    font-size: 14px;
    line-height: 21px;
  }
}

.section-footer,
.footer {
  background-color: #1c1c1c;
  padding: 10rem 0 0 0;
}
.section-footer .placeholder,
.footer .placeholder {
  display: none;
}
.section-footer .link,
.footer .link {
  background-color: grey;
}
.section-footer .link__content,
.footer .link__content {
  width: 86%;
  margin: 0 auto;
}
.section-footer__content,
.footer__content {
  width: 86%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  color: #6f6f6f;
  margin-bottom: 10rem;
}
.section-footer__content .left h1,
.footer__content .left h1 {
  color: #147acf;
  font-size: 4rem;
  font-weight: bold;
}
.section-footer__content .left ul,
.footer__content .left ul {
  padding-left: 4rem;
}
.section-footer__content .right,
.footer__content .right {
  display: flex;
  justify-content: space-between;
  width: 70%;
}
.section-footer__content .right dl dt,
.footer__content .right dl dt {
  font-size: 1.6rem;
  margin-bottom: 3rem;
  color: #bbbbbb;
}
.section-footer__content .right dl dd,
.footer__content .right dl dd {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}
.section-footer__content .right dl dd:hover,
.footer__content .right dl dd:hover {
  color: #fff;
}
.section-footer__content .right dl a,
.section-footer__content .right dl a:active,
.footer__content .right dl a,
.footer__content .right dl a:active {
  color: inherit;
  text-decoration: none;
}
.section-footer .copyright,
.footer .copyright {
  background-color: #161616;
  padding: 3rem 0;
  color: #606060;
  text-align: center;
}
@media only screen and (max-width: 36em) {
  .section-footer,
  .footer {
    padding: 0;
  }
  .section-footer .placeholder,
  .footer .placeholder {
    display: block;
    width: 100%;
    height: 72px;
    background: white;
  }
  .section-footer .footer__content,
  .section-footer .copyright,
  .footer .footer__content,
  .footer .copyright {
    display: none;
  }
}

.about {
  font-size: 1.6rem;
  line-height: 3.2rem;
  letter-spacing: 0.2rem;
}
.about .heading {
  text-align: center;
  font-size: 3rem;
  font-weight: 100;
  margin: 0 auto;
}
.about ul {
  list-style-position: inside;
}
.about ul li {
  padding-left: 4rem;
}
.about p {
  margin-top: 2rem;
  text-indent: 2.8rem;
}
.about .intro {
  padding: 5rem;
  background-image: linear-gradient(to right bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(../../public/assets/images/about-us-background.jpg);
  background-size: cover;
}
.about .claim {
  padding: 5rem;
  background-image: linear-gradient(to right bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(../../public/assets/images/claim-background-close-up-of-judge-raising-gavel-in-courtroom.jpg);
  background-size: cover;
}
.services {
  font-size: 1.6rem;
  line-height: 3.2rem;
  letter-spacing: 0.2rem;
}
.services h1 {
  text-align: center;
  font-size: 3rem;
  font-weight: 100;
  margin: 0 auto;
}
.services ul {
  list-style-position: inside;
}
.services ul li {
  padding-left: 4rem;
}
.services p {
  margin-top: 2rem;
  text-indent: 2.8rem;
}
.services .qms {
  padding: 5rem;
  background-image: linear-gradient(to right bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(../../public/assets/images/qms-background.jpg);
  background-size: cover;
}
.services .ems {
  padding: 5rem;
  background-image: linear-gradient(to right bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(../../public/assets/images/ems-background.jpg);
  background-size: cover;
}
.services .ohsms {
  padding: 5rem;
  background-image: linear-gradient(to right bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(../../public/assets/images/ohsms-background.jpg);
  background-size: cover;
}

.notfound {
  position: relative;
}
.notfound img {
  width: 100%;
  height: 84vh;
}
.notfound .contents {
  position: absolute;
  left: 10%;
  top: 20%;
  text-align: center;
}
.notfound .contents h1 {
  font-size: 4rem;
}
.notfound .contents p {
  font-size: 6rem;
  color: white;
  font-weight: bold;
}
.notfound .contents .btn, .notfound .contents .btn:link, .notfound .contents .btn:visited {
  text-decoration: none;
  padding: 1.5rem 4rem;
  display: inline-block;
  border-radius: 10rem;
  transition: all 0.2s;
  position: relative;
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 1rem;
  border: none;
  cursor: pointer;
}
.notfound .contents .btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.notfound .contents .btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}
.notfound .contents .btn:active, .notfound .contents .btn:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.notfound .contents .btn--white {
  background-color: #fff;
  color: #004a89;
}
.notfound .contents .btn--white::after {
  background-color: #fff;
}
.notfound .contents .btn--green {
  background-color: #55c57a;
  color: #fff;
}
.notfound .contents .btn--green::after {
  background-color: #55c57a;
}
.notfound .contents .btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}
.notfound .contents .btn--animated {
  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
}

.cert-query {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  background-color: lightgray;
}
.cert-query .input-field {
  margin-bottom: 2rem;
  width: 100%;
  text-align: center;
}
.cert-query .input-field input {
  width: 80%;
  font-size: 1.5rem;
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
  border: none;
}
.cert-query .button {
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.6rem;
  border-radius: 0.2rem;
  padding: 1rem 2rem;
}
.cert-query .result {
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 300px;
}

.contact {
  text-align: center;
  background-color: lightgrey;
}
.contact h1 {
  padding: 1.5rem 2rem;
  margin-bottom: 2rem;
}
.contact input {
  width: 39%;
  font-size: 1.5rem;
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
  border: none;
}
.contact input:not(:last-of-type) {
  margin-right: 2%;
}
.contact textarea {
  border: none;
  font-size: 1.5rem;
  margin-top: 2rem;
  width: 80%;
  border-radius: 0.5rem;
  padding: 0 0 10%;
}
.contact button {
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.6rem;
  border-radius: 0.2rem;
  padding: 1rem 2rem;
}

