.contact {
  //   border: 1px solid;
  text-align: center;
  background-color: lightgrey;

  h1 {
    padding: 1.5rem 2rem;
    margin-bottom: 2rem;
  }
  input {
    width: 39%;
    font-size: 1.5rem;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    border: none;

    &:not(:last-of-type) {
      margin-right: 2%;
    }
  }

  textarea {
    border: none;
    font-size: 1.5rem;
    margin-top: 2rem;
    width: 80%;
    border-radius: 0.5rem;
    padding: 0 0 10%;
  }

  button {
    padding: 0.5rem 1rem;
    margin-bottom: 2rem;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;

    font-size: 1.6rem;

    border-radius: 0.2rem;
    padding: 1rem 2rem;
  }
}
