@use "../abstracts" as *;

.section-industry {
  width: 86%;
  margin: 20rem auto 10rem;
  position: relative;
  overflow: hidden;
  height: 60rem;
  color: $color-white;

  @include responsive(xsmall) {
    width: 100%;
    height: auto;
    margin: 0;
  }

  .left {
    width: 30%;
    padding: 10% 5% 0 5%;
    background-image: url(../../public/assets/images/OK.jpg);
    background-size: cover;
    transition: background-image 2s ease-in-out;
    h1 {
      font-size: 3.5rem;
    }
    h3 {
      font-size: 1.6rem;
    }
    p {
      font-size: 1.6rem;
      margin-top: 2.1rem;
      line-height: 2.9rem;
    }
    @include responsive(xsmall) {
      h1 {
        font-size: 24px;
        line-height: 36px;
      }
      h3,
      p {
        font-size: 14px;
        line-height: 21px;
      }
      p {
        margin-top: 10px;
      }
    }
  }

  .right {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 70%;

    @include responsive(xsmall) {
      // flex-direction: column;
      display: block;
      text-align: center;
    }
    ul {
      border: 1px solid;
      padding-left: 40px;

      @include responsive(xsmall) {
        border: none;
      }
    }

    .box {
      height: 40%;
      overflow: hidden;
      color: $color-white;
      position: relative;
      transition: all 1s ease;
      background-size: 100% 100%;
      background-position: center;
      margin-left: 1rem;

      @include responsive(xsmall) {
        padding: 6px;
        text-align: left;
        height: 23%;
        box-shadow: 0px 0px 10px 0px $color-black;

        &:not(:last-of-type) {
          margin-bottom: 12px;
        }
      }

      a,
      a:focus,
      a:active {
        color: inherit;
        text-decoration: none;
      }
      .info-cell {
        opacity: 0;
        transition: all 1s ease;
        background: rgba(0, 0, 0, 0.6);
        margin: 2rem auto;

        @include responsive(xsmall) {
          opacity: 1;
          background: rgba(0, 0, 0, 0.3);
          overflow: hidden;
          height: 70%;
          font-size: 12px;
          line-height: 18px;
        }
        .title {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 1rem;
          @include responsive(xsmall) {
            display: none;
          }
        }
      }

      .title-cell {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 5rem;
        padding: 0 2rem;
        font-size: 1.8rem;
        font-weight: bold;
        background: rgba(0, 0, 0, 0.5);
        line-height: 5rem;
        transition: all 1s ease;

        @include responsive(xsmall) {
          font-size: 14px;
        }
      }

      &:hover {
        background-size: 110% 110%;
        box-shadow: 0 1.5rem 4rem rgba($color-black, 0.5);
      }

      &:hover .title-cell {
        opacity: 0;
      }
      &:hover .info-cell {
        opacity: 1;
      }
    }

    > div:nth-of-type(1) {
      width: 35%;
      background-image: url(../../public/assets/images/Industry-data-science-big-technology-scientist-computing.jpg);
    }
    > div:nth-of-type(2) {
      width: 60%;
      background-image: url(../../public/assets/images/Industry-future-building-construction-engineering-project-concept.jpg);
    }
    > div:nth-of-type(3) {
      width: 60%;
      background-image: url(../../public/assets/images/Industry-balanced-diet-healthy-food-v1.jpg);
    }
    > div:nth-of-type(4) {
      width: 35%;
      background-image: url(../../public/assets/images/Industry-designer-sketching-drawing-design-development-product-plan-draft-chair-armchair.jpg);
    }

    @include responsive(xsmall) {
      > div {
        width: 100% !important;
      }
    }
    > a {
      color: $midnightBlue;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
