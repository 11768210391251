@use "../abstracts" as *;

.section-svc {
  margin: 5rem auto;
  padding: 10rem 0;
  width: 100%;

  background-image: linear-gradient(
      //   105deg,
      to bottom,
      rgba($color-white, 0),
      rgba($color-primary, 1),
      // transparent 50%
    ),
    url(../../public/assets/images/SVC-ISO-background.jpeg);
  background-size: cover;
  background-position: center;

  @include responsive(xsmall) {
    margin: 0 auto;
    padding: 5rem 0;
    background-image: linear-gradient(
        to bottom,
        rgba($color-white, 1),
        rgba($color-primary, 0.5)
      ),
      url(../../public/assets/images/SVC-ISO-background.jpeg);
  }

  .title {
    position: relative;
    right: 7%;
    bottom: 10rem;
    padding-left: 14%;
    background-color: white;

    h1 {
      font-size: 3.5rem;
    }
    h3 {
      color: $midnightBlue;
      font-size: 1.6rem;
    }

    @include responsive(xsmall) {
      // width < 576?
      // position: ;
      right: auto;
      bottom: auto;
      text-align: center;
      // position: static;
      padding-left: 0;
      background-color: transparent;

      h1 {
        font-size: 24px;
      }
      h3 {
        font-size: 14px;
      }
    }
  }

  p {
    font-size: 2rem;
    color: $color-white;
    margin-top: 2.1rem;
    width: 50%;
    margin: 0 auto;
    background: rgba($color-black, 0.5);
    padding: 1rem 5rem;

    @include responsive(xsmall) {
      width: 100%;
      text-align: center;
      background: none;
      color: #666666;
      font-size: 14px;
    }
  }

  .services {
    width: 86%;
    margin: 0 auto;

    position: relative;
    top: 15rem;
    color: $color-white;
    font-size: 2rem;

    @include responsive(xsmall) {
      // width: 100%;
      top: auto;
      margin-top: 5rem;
      font-size: 16px;
    }

    .svc {
      width: calc(100% / 3);
      //   max-width: 520px;
      //   padding: 0 0 70%;
      //   border: 1px solid;
      display: inline-block;
      overflow: hidden;
      position: relative;

      @include responsive(xsmall) {
        display: block;
        width: 100%;

        box-shadow: 0px 0px 10px 0px $color-black;

        &:not(:last-of-type) {
          margin-bottom: 2rem;
        }
      }

      img {
        width: 100%;
        vertical-align: top;
        transition: all 3s;
        &:hover {
          transform: scale(1.1);
        }
      }

      &__info {
        background: rgba($color-black, 0.5);
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 30%;

        color: inherit;
        .title-en {
          position: absolute;
          top: 1rem;
          left: 1rem;
        }

        .title-cn {
          position: absolute;
          bottom: 1rem;
          right: 1rem;
        }
      }
    }
  }
}
