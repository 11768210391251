@use "../abstracts" as *;

.banner {
  position: relative;
  background: #000;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;

  ul {
    list-style: none;
    position: relative;
    overflow: hidden;
    width: 4200px;
    transition: left 1s;

    li {
      width: 1400px;
      float: left;
      height: 65vh;
      position: relative;

      &:hover {
        cursor: pointer;
      }

      .text {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10%;
        color: $color-white;

        h1 {
          font-size: 6rem;
          font-weight: 600;
          text-shadow: 1px 1px 1px $color-black;
          transform: perspective(50rem) rotateX(10deg);
        }

        p {
          font-size: 2rem;
          font-weight: bold;
          text-shadow: 1px 1px 1px $color-black;
        }
      }
    }

    li:nth-of-type(1) {
      background-image: linear-gradient(
          to right bottom,
          rgba($color-black, 0.1),
          rgba($color-black, 0.1)
        ),
        url(../../public/assets/images/banner-sailing-tallship-gm176827711-1331024.jpg);
      background-size: cover;

      p {
        font-size: 3rem;
        font-weight: bold;
      }
    }

    li:nth-last-of-type(2) {
      background-image: linear-gradient(
          to right bottom,
          rgba($color-black, 0.1),
          rgba($color-black, 0.3)
        ),
        url(../../public/assets/images/banner-various-field-industry-concept-sustainable-society.jpg);
      background-size: cover;

      p {
        // font-size: 4rem;
        font-weight: bold;
        width: 50rem;
      }
    }

    li:nth-of-type(3) {
      background-image: linear-gradient(
          to right bottom,
          rgba($color-black, 0.1),
          rgba($color-black, 0.1)
        ),
        url(../../public/assets/images/banner-businessmen-making-handshake-with-partner-greeting-dealing-merger-and-acquisition.jpg);
      background-size: cover;

      p {
        font-size: 2rem;
        font-weight: bold;
        width: 50rem;
      }
    }
  }

  .dots {
    display: flex;
    justify-content: center;

    position: absolute;
    bottom: 3rem;
    width: 100%;

    .dot {
      // display: inline-block;
      width: 2rem;
      height: 2rem;
      margin: 0 2rem;
      border-radius: 50%;
      border: 2px solid white;
      background-color: transparent;
      // margin: 0 5px;
      cursor: pointer;

      &.active {
        background-color: white;
      }
    }
  }
}
