@use "../abstracts" as *;

.about {
  // padding: 5rem;
  font-size: 1.6rem;
  line-height: 3.2rem;
  letter-spacing: 0.2rem;

  .heading {
    text-align: center;
    font-size: 3rem;
    font-weight: 100;
    margin: 0 auto;
  }

  ul {
    list-style-position: inside;

    li {
      padding-left: 4rem;
    }
  }
  p {
    margin-top: 2rem;
    text-indent: 2.8rem;
  }

  .intro {
    padding: 5rem;
    background-image: linear-gradient(
        to right bottom,
        rgba($color-white, 0.7),
        rgba($color-white, 0.7)
      ),
      url(../../public/assets/images/about-us-background.jpg);
    background-size: cover;
  }

  .claim {
    padding: 5rem;
    background-image: linear-gradient(
        to right bottom,
        rgba($color-white, 0.7),
        rgba($color-white, 0.7)
      ),
      url(../../public/assets/images/claim-background-close-up-of-judge-raising-gavel-in-courtroom.jpg);
    background-size: cover;
  }

  .academic {
  }
}
