@use "../abstracts" as *;

.notfound {
  position: relative;

  img {
    width: 100%;
    height: 84vh;
  }

  .contents {
    position: absolute;
    left: 10%;
    top: 20%;
    text-align: center;

    h1 {
      font-size: 4rem;
      //   display: inline-block;
      //   position: relative;
      //   left: 0;
    }

    p {
      font-size: 6rem;
      color: white;
      font-weight: bold;
    }

    .btn {
      &,
      &:link,
      &:visited {
        text-decoration: none;
        padding: 1.5rem 4rem;
        display: inline-block;
        border-radius: 10rem;
        transition: all 0.2s;
        position: relative;
        font-size: $default-font-size;
        font-weight: bold;
        margin-top: 1rem;

        //Change for the <button> element
        border: none;
        cursor: pointer;
      }

      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 1rem 2rem rgba($color-black, 0.2);

        &::after {
          transform: scaleX(1.4) scaleY(1.6);
          opacity: 0;
        }
      }

      &:active,
      &:focus {
        outline: none;
        transform: translateY(-1px);
        box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
      }

      &--white {
        background-color: $color-white;
        color: $midnightBlue;

        &::after {
          background-color: $color-white;
        }
      }

      &--green {
        background-color: $color-primary;
        color: $color-white;

        &::after {
          background-color: $color-primary;
        }
      }

      &::after {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        border-radius: 10rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all 0.4s;
      }

      &--animated {
        animation: moveInBottom 0.5s ease-out 0.75s;
        animation-fill-mode: backwards;
      }
    }
  }
}
