@use "../abstracts" as *;
.container {
  width: 86%;
  margin: 10rem auto;

  // border: 1px solid;
  // background-color: rgba(255, 255, 255, 0.8);
  // z-index: 1;

  display: flex;

  .left {
    width: 20%;
    background-color: $midnightBlue;

    ul {
      list-style: none;
      color: $color-white;
      text-align: center;
      // padding: 3rem;

      background-color: $midnightBlue;

      li {
        padding: 1rem;
        // border: 1px solid;

        font-size: 1.6rem;
        transition: all 0.3s;
        &:hover {
          background-color: #147acf;
          cursor: pointer;
        }

        border-bottom: 1px solid #147acf;
      }
    }

    a,
    a:active {
      text-decoration: none;
    }
  }

  .right {
    width: 80%;
    // border: 1px solid;
  }

  // @include responsive(large) {
  //   max-width: 100%;
  //   padding: 0 5rem;
  // }
  // @include responsive(medium) {
  //   max-width: 100%;
  //   padding: 0 4rem;
  // }
  // @include responsive(small) {
  //   max-width: 100%;
  //   padding: 0 2rem;
  // }
  // @include responsive(xsmall) {
  //   max-width: 100%;
  //   padding: 0 1.5rem;
  // }

  @include responsive(xsmall) {
    width: 100%;
    margin: 0;
    flex-direction: column;
    // display: block;

    .left {
      width: 100%;

      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          font-size: 14px;
          a {
            padding: 1rem;
          }
        }
      }
    }

    .right {
      width: 100%;
    }
    h1 {
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }

    li {
      font-size: 14px;
    }
  }
}
.pr {
  position: relative;
}
.group {
  margin-bottom: 1.3rem;
  span {
    font-size: 1.5rem;
    display: inline-block;
    color: $blackDark;
    cursor: pointer;
  }
  &__control {
    @include control(5rem, 0, 1.5rem, $silverLight-45);
  }
  &__textarea {
    @include control(10rem, 1.5rem, 1.5rem, $silverLight-45);
    &::placeholder {
      font-family: sans-serif;
      font-size: 1.5rem;
      @include responsive(xsmall) {
        font-size: 1.3rem;
      }
    }
  }
}
::placeholder {
  color: $blackDark;
  font-size: 1.5rem;
  @include responsive(xsmall) {
    font-size: 1.3rem;
  }
}
.flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.y-center {
  align-items: center;
}
.p-15 {
  padding: 1.5rem;
}
.mr-minus-15 {
  margin-right: -1.5rem;
}
.ml-minus-15 {
  margin-left: -1.5rem;
}
.mb-55 {
  margin-bottom: 5.5rem !important;
}
// .heading {
//   margin-bottom: 4rem;
//   font-size: 2rem;
//   font-weight: 400;
//   background: linear-gradient(to right, $main1, $main2);
//   background-clip: text;
//   color: transparent;
//   transform: rotate(-2deg);
//   text-transform: capitalize;
//   @include responsive(xsmall) {
//     font-size: 1.8rem;
//   }
// }
.animation {
  transform: translateY(-10rem) scale(0);
  opacity: 0;
  transition: all 0.8s ease-out;
}
.animate {
  transform: translateY(0) scale(1);
  opacity: 1;
}
.headingAnimation {
  transform: translateY(-10rem) scale(0) rotate(-2deg);
  opacity: 0;
  transition: all 0.8s ease-out;
}
.headingAnimate {
  transform: translateY(0) scale(1) rotate(-2deg);
  opacity: 1;
}

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, $lightBlue, $darkBlue);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.2rem;
  transition: all 0.2s;

  //   @include respond(tab-port) {
  //     font-size: 3rem;
  //   }

  //   @include respond(phone) {
  //     font-size: 2.5rem;
  //   }

  &:hover {
    transform: skewY(2deg) skewX(15deg) scale(1.1);
    text-shadow: 0.5rem 1rem 2rem rgba(black, 0.2);
  }
}

// ul {
//   list-style: none;
//   // width: 80%;
//   margin: 0 auto;
// }

a,
a:active {
  color: inherit;
  text-decoration: none;
}
