@use "../abstracts" as *;

.navbar {
  display: flex;
  align-items: center;
  // justify-content: space-between;

  position: sticky;
  top: 0;

  z-index: 2;
  padding: 0 3%;

  color: $color-white;
  background: rgba(9, 30, 55, 0.8);

  transition: 0.1s;

  @include responsive(xsmall) {
    // width < 576?
    display: block;
    padding: 0;
  }

  .navigation {
    width: 70%;
    display: flex;
    justify-content: space-between;
    position: relative;

    .logo {
      font-size: 5rem;
      font-weight: bold;
    }

    &__checkbox,
    &__button {
      display: none;
    }

    nav {
      // padding: 0 5.4rem;
      margin-right: 3rem;
      width: 80%;
      font-size: 1.6rem;

      > ul {
        list-style: none;
        display: flex;
        justify-content: space-around;

        > li {
          display: flex;
          text-align: center;
          position: relative;
          width: 100%;

          &:hover > a {
            color: $color-white;
            background-color: rgba(65, 65, 65, 0.85);
          }

          > a.active,
          a:focus {
            color: $color-white;
            background-color: rgba(65, 65, 65, 0.85);
          }
          span {
            display: none;
          }
          > a {
            width: 100%;
            color: #d9d9d9;
            padding: 3rem 0;
            text-align: center;
            transition: all 0.3s;
          }

          > ul {
            top: 100%;
            // bottom: 100%;
            left: 0;
            width: 100%;
            position: absolute;
            opacity: 0;
            visibility: hidden;
            list-style-type: none;
            transition: all 0.5s ease-in;

            &.open {
              position: 100%;
              opacity: 1;
              visibility: visible;
            }

            li {
              text-align: center;
              font-size: 1.4rem;
              padding: 1rem;
              position: relative;
              background: rgba(65, 65, 65, 0.85);

              &:not(:last-child) {
                // border-bottom: 1px solid;
                margin-bottom: 1px;
              }

              a {
                color: #bcbcbc;
                transition: all 0.5s;
              }

              &:hover > a {
                color: $color-white;
              }
            }
          }
          // hover 实现, 缺点是无法兼容手机端
          // &:hover > ul {
          //   opacity: 1;
          //   visibility: visible;
          // }
        }
      }
    }
    @include responsive(xsmall) {
      // width < 576?
      display: block;
      width: 100%;

      .logo {
        font-size: 36px;
        padding: 3px 3%;
        height: 60px;
        line-height: 36px;
        padding-top: 12px;
        vertical-align: middle;
        display: inline-block;
      }

      &__button {
        display: inline-block;
        position: absolute;
        background-color: $color-white;
        height: 42px;
        width: 42px;
        top: 9px;
        right: 3%;
        border-radius: 50%;
        box-shadow: 0 6px 18px rgba($color-black, 0.1);
        text-align: center;
        cursor: pointer;
      }

      &__icon {
        position: relative;
        margin-top: 21px;

        &,
        &::before,
        &::after {
          width: 18px;
          height: 2px;
          background-color: $color-grey-dark-3;
          display: inline-block;
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          left: 0;
          transition: all 0.2s;
        }

        &::before {
          top: -4.8px;
        }
        &::after {
          top: 4.8px;
        }
      }

      .navigation__button {
        &:hover .navigation__icon::before {
          top: -6px;
        }
      }

      .navigation__button {
        &:hover .navigation__icon::after {
          top: 6px;
        }
      }

      .navigation__checkbox:checked + .navigation__button .navigation__icon {
        background-color: transparent;
      }

      .navigation__checkbox:checked
        + .navigation__button
        .navigation__icon::before {
        top: 0;
        transform: rotate(135deg);
      }

      .navigation__checkbox:checked
        + .navigation__button
        .navigation__icon::after {
        top: 0;
        transform: rotate(-135deg);
      }

      nav {
        display: none;
        width: 100%;

        > ul {
          display: block;

          > li {
            display: block;
            width: auto;
            // padding: 0 5%;
            border-top: 1px solid #ddd;
            text-align: left;
            position: relative;

            &:hover > a {
              color: $color-white;
              background-color: transparent;
            }
            > a {
              width: auto;
              padding: 12px;
              font-size: 12px;
              display: inline-block;
              padding-left: 5%;
              font-weight: 700;
            }
            > span {
              position: absolute;
              top: 6px;
              right: 7.5%;
              display: inline-block;
              font-size: 18px;
              font-weight: 700;
            }
            > ul {
              position: relative;
              // top: auto;
              width: auto;
              display: none;

              &.open {
                bottom: auto;
                top: 100%;
                display: block;
              }

              > li {
                display: block;
                width: auto;
                border-top: 1px solid #ddd;
                text-align: left;
                position: relative;
                padding: 0 10%;
                background-color: transparent;

                > a {
                  width: auto;
                  padding: 12px;
                  font-size: 12px;
                  display: inline-block;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }

      .navigation__checkbox:checked ~ nav {
        display: block;
      }
    }
  }

  //----
  // Login Language Search section
  .lls {
    display: flex;
    align-items: center;
    white-space: nowrap;

    width: 30%;

    @include responsive(xsmall) {
      // width < 576?
      display: none;
      width: 100%;
    }

    a::before {
      width: 1.3rem;
      height: 1.6rem;
      background-image: url(../../public/assets/images/icon-member.svg);
      content: "";
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin: -0.5rem 1rem 0 0;
    }

    a:hover {
      color: $ceruleanBlue;
    }

    a {
      color: inherit;
      opacity: 0.8;
      padding: 0.2rem 0;
      font-size: 1.4rem;
      transition: color 0.3s;
    }
    .lang {
      padding-left: 3rem;

      span::before,
      span::after {
        width: 1.7rem;
        height: 1.7rem;
        content: "";
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      span::before {
        background-image: url(../../public/assets/images/icon-lang.svg);
        margin: -0.5rem 1rem 0 0;
      }

      span::after {
        background-image: url(../../public/assets/images/icon-downarrow.png);
        margin: -0.5rem 0 0 1rem;
      }
      span:hover {
        cursor: pointer;
        color: $ceruleanBlue;
      }

      span {
        font-size: 1.4rem;
        opacity: 0.8;
        transition: color 0.3s;
      }
      ul {
        display: none;
      }
    }

    .search {
      margin-left: 3rem;
      // padding: 2.3rem 0;

      button::after {
        width: 1.7rem;
        height: 1.7rem;
        background-image: url(../../public/assets/images/icon-search.svg);
        content: "";
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin: -5px 0 0 10px;

        // background-color: white;
      }

      button {
        border: 0.2rem solid #004a89;
        background: #004a89;
        color: white;
        font-size: 1.4rem;
        padding: 10px 25px;
      }

      button:hover {
        background: #147acf;
        border-color: #147acf;
        cursor: pointer;
      }
      .form-wrap {
        display: none;
      }
    }
  }
}
