@use "../abstracts" as *;

.section-about {
  width: 86%;
  // letter-spacing: 0.1rem;
  padding: 10rem;
  margin: 10rem auto;
  color: $color-white;
  // font-family: DIN1451;
  background-image: linear-gradient(
      to right bottom,
      rgba($color-white, 0.5),
      rgba($color-black, 0.9)
    ),
    url(../../public/assets/images/about.jpg);
  background-size: cover;

  display: flex;
  justify-content: space-between;

  .sabout,
  .join {
    position: relative;
    padding: 5rem 3rem;
    width: 40%;
    background: rgba($lightBlue, 0.5);
    border-radius: 1rem;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
    margin: 0 auto;
    transition: transform 0.3s;

    > * {
      text-align: center;
    }

    &:hover {
      cursor: pointer;
      transform: translateY(-1.5rem) scale(1.03);
    }

    @include responsive(xsmall) {
      padding: 2rem;
      width: 100%;
    }
  }

  // .join {
  //   padding: 10rem;
  //   width: 40%;
  //   background: rgba(0, 0, 0, 0.5);
  // }

  h1 {
    // width: 100%;
    font-size: 3.5rem;
    // text-align: center;
    // line-height: calc(1.5 * 3.5) rem;
  }

  p {
    font-size: 1.6rem;
    // line-height: calc(1.5 * 1.6) rem;
  }

  a,
  a:active {
    color: inherit;
    text-decoration: none;
  }

  @include responsive(xsmall) {
    width: 100%;
    margin: 0 auto;
    padding: 5rem 3%;
    letter-spacing: normal;
    flex-direction: column;

    .sabout {
      margin-bottom: 2rem !important;
    }
    .join {
      background: rgba(51, 51, 51, 0.77) !important;
    }

    h1 {
      font-size: 24px;
      line-height: 36px;
    }

    h3,
    p {
      font-size: 14px;
      line-height: 21px;
    }
  }
}
