@use "../abstracts" as *;

.section-footer,
.footer {
  background-color: #1c1c1c;
  padding: 10rem 0 0 0;

  .placeholder {
    display: none;
  }
  .link {
    // border: 1px solid;
    background-color: grey;
    &__content {
      width: 86%;
      margin: 0 auto;
    }
  }
  &__content {
    width: 86%;
    margin: 0 auto;
    // border: 1px solid;
    display: flex;
    justify-content: space-between;
    color: #6f6f6f;
    margin-bottom: 10rem;
    .left {
      h1 {
        // color: $skyBlue;
        color: #147acf;
        font-size: 4rem;
        font-weight: bold;
      }
      ul {
        padding-left: 4rem;
      }
    }
    .right {
      //   border: 1px solid;
      display: flex;
      justify-content: space-between;
      width: 70%;
      dl {
        dt {
          font-size: 1.6rem;
          margin-bottom: 3rem;
          color: #bbbbbb;
        }
        dd {
          font-size: 1.4rem;
          margin-bottom: 1rem;
          &:hover {
            color: $color-white;
          }
        }
        a,
        a:active {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }
  .copyright {
    background-color: #161616;
    // border-top: 1px solid rgb(84, 84, 84);
    padding: 3rem 0;
    color: #606060;
    text-align: center;
  }

  @include responsive(xsmall) {
    padding: 0;
    .placeholder {
      display: block;
      width: 100%;
      height: 72px;
      background: white;
    }

    .footer__content,
    .copyright {
      display: none;
    }
  }
}
