$white: #fff;
$black: #000;
$blackDark: #1c1d1f;
$main1: #ff4d58;
$main2: #df2189;
$silver: #7f8c8d;
$silverDark: #777;
$silverlight: #aaa;
$silverLight-50: #d4cfcf;
$silverLight-45: #e2e4eb;
$silverLight-40: #f6f6fb;
$orange: #fbbf24;
$green: #34d399;

$darkBlue: #007bff; //（深蓝色）
$ceruleanBlue: #2095f7;
$dodgerBlue: #147acf;
$skyBlue: #00bfff; //（天蓝色）
$lightBlue: #5bc0de; //（浅蓝色）
$darkGrey: #333333; //（深灰色）
$lightGrey: #f8f9fa; //（浅灰色）

$darkBlue: #0a1a2e;
$midnightBlue: #004a89;
$darkGrey: #212121;

// COLORS
$color-primary: #55c57a;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;
// $color-primary-light: LightBlue;
// $color-primary-dark: #2998ff;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;
// FONT
$default-font-size: 1.6rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 3rem;
