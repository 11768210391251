@use "../abstracts" as *;

.services {
  font-size: 1.6rem;
  line-height: 3.2rem;
  letter-spacing: 0.2rem;

  h1 {
    text-align: center;
    font-size: 3rem;
    font-weight: 100;
    margin: 0 auto;
  }

  ul {
    list-style-position: inside;

    li {
      padding-left: 4rem;
    }
  }
  p {
    margin-top: 2rem;
    text-indent: 2.8rem;
  }

  .qms {
    padding: 5rem;
    background-image: linear-gradient(
        to right bottom,
        rgba($color-white, 0.7),
        rgba($color-white, 0.7)
      ),
      url(../../public/assets/images/qms-background.jpg);
    background-size: cover;
  }

  .ems {
    padding: 5rem;
    background-image: linear-gradient(
        to right bottom,
        rgba($color-white, 0.7),
        rgba($color-white, 0.7)
      ),
      url(../../public/assets/images/ems-background.jpg);
    background-size: cover;
  }

  .ohsms {
    padding: 5rem;
    background-image: linear-gradient(
        to right bottom,
        rgba($color-white, 0.7),
        rgba($color-white, 0.7)
      ),
      url(../../public/assets/images/ohsms-background.jpg);
    background-size: cover;
  }
}
