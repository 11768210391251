@use "../abstracts" as *;

.header {
  width: 100%;
  position: relative;

  &__img {
    width: 100%;
    position: relative;
    padding-top: 20%;
    overflow: hidden;
  }

  &__img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h1 {
    font-size: 5rem;
    color: $color-white;
    text-shadow: 1px 1px 1px $color-black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}
